#main,
#platform {
    animation: mchart 4s
}

@keyframes mchart {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

#chart {
    animation: tchart 5s ease
}

@keyframes tchart {
    from {
        transform: rotateZ(240deg)
    }

    to {
        transform: rotateZ(360deg)
    }
}

#person {
    animation: rperson 4s ease;
}

@keyframes rperson {
    from {
        transform: translateX(100%)
    }

    to {
        transform: translate(0%)
    }
}